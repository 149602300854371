<script>
  import { Line, mixins } from 'vue-chartjs'
  import head from 'lodash/head'
  import last from 'lodash/last'
  import orderBy from 'lodash/orderBy'

  export default {
    name: 'chart',
    extends: Line,
    mixins: [mixins.reactiveData],
    props: ['items'],
    computed: {
      labels() {
        return (this.reversed || []).map(item => {
          return moment(Date.parse(item.date)).format('dd D MMM')
        })
      },
      data() {
        return (this.reversed || []).map(item => {
          return parseFloat(numeral(item.seconds / 60 / 60).format('0.[00]'))
        })
      },
      dataCollection() {
        return {
          labels: this.labels,
          datasets: [
            {
              label: 'Отработанные часы',
              backgroundColor: '#42a5f5',
              data: this.data,
              fill: false,
              pointHitRadius: 50,
            },
          ],
        }
      },
      options() {
        let afterBody = tooltipItem => {
          if (tooltipItem && tooltipItem[0] && tooltipItem[0].index + 1) {
            let index = tooltipItem[0].index,
              item = this.reversed[index]

            if (item && item.items) {
              let subItems = item.items,
                employees = {},
                projects = {}

              subItems.forEach(subItem => {
                if (subItem.user) {
                  employees[subItem.user.id] = employees[subItem.user.id] || {
                    name: subItem.user.name,
                    seconds: 0,
                  }

                  employees[subItem.user.id].seconds += subItem.seconds
                }
              })

              employees = orderBy(employees, ['seconds'], ['desc'])

              let slicedEmployees = employees.slice(0, 6)

              let result = [
                '',
                'По сотрудникам:',
                ...slicedEmployees.map(e => {
                  return `• ${e.name} - ${moment.duration(e.seconds, 'seconds').format('h[ч] m[м]', { trim: true })}`
                }),
              ]

              if (employees.length > 6) {
                result.push('• ...')
              }

              projects = orderBy(item.projects, ['seconds'], ['desc'])

              if (projects.length) {
                let slicedProjects = projects.slice(0, 6)

                result = [
                  ...result,
                  '',
                  'По проектам:',
                  ...slicedProjects.map(p => {
                    return `• ${p.name} - ${moment.duration(p.seconds, 'seconds').format('h[ч] m[м]', { trim: true })}`
                  }),
                ]

                if (projects.length > 6) {
                  result.push('• ...')
                }
              }

              return result
            }
          } else return ''
        }

        let label = (tooltipItem, data) => {
          let label = data.datasets[tooltipItem.datasetIndex].label + ': ',
            tooltipData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

          label += moment.duration(tooltipData, 'hours').format('h[ч] m[м]', {
            trim: true,
          })

          return label
        }

        return {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
            position: 'bottom',
          },
          tooltips: {
            callbacks: { afterBody, label },
            bodyFontSize: 10.5,
            // intersect: false
          },
        }
      },
      reversed() {
        let reversed = (this.items && this.items.filter(item => item.date).reverse()) || []

        let dataRange = reversed.length && {
          from: moment(Date.parse(head(reversed).date)),
          to: moment(Date.parse(last(reversed).date)),
        }

        if (dataRange) {
          let diff = dataRange.to.diff(dataRange.from, 'days') + 1

          if (reversed.length !== diff) {
            let dates = []
            for (let day = dataRange.from; day <= dataRange.to; day.add(1, 'd')) {
              let find = reversed.find(item => {
                return day.format('YYYY-MM-DD') === item.date
              })

              if (find) {
                dates.push(find)
              } else {
                dates.push({
                  date: day.format('YYYY-MM-DD'),
                  seconds: 0,
                })
              }
            }

            return dates
          } else return reversed
        } else return []
      },
    },
    mounted() {
      this.chartData = this.dataCollection
      this.renderChart(this.chartData, this.options)
    },
    watch: {
      dataCollection(data) {
        this.chartData = data
      },
    },
  }
</script>
